import React, {Component} from 'react';
import { Breadcrumb, BreadcrumbItem, Button, UncontrolledCollapse, Card, CardBody} from 'reactstrap';
import { Link } from 'react-router-dom';


class Job extends Component{

    render(){
        return(
            <div className="container">
                <div className="row">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/home">SEPCH</Link></BreadcrumbItem>
                        <BreadcrumbItem><Link to="/careers">Careers</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Job</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="col-12">
                        <h3>Job</h3>
                        <hr />
                    </div> 
                    <div className="col-12 mt-5">
                        <p><i className="fa fa fa-clock-o" style={{color:"red"}}></i> We are hiring</p>
                    </div>
                    <div className="col-12 mt-5">
                        
                        <Button color="secondary" id="toggler1" style={{ marginBottom: '1rem', fontWeight:"bold" , width: "250px", textAlign: "left" }}>
                            Health Care Aide <span class="badge badge-warning">Closed</span>
                        </Button>
                        
                        <UncontrolledCollapse toggler="#toggler1">
                            <Card>
                                <CardBody>
                                <p style={{ fontWeight:"bold"}}>Education:</p>
                                    <ul>
                                        <li>Grade XII or equivalent.</li>
                                        <li>Health Care Aide Certificate from a recognized educational institution.</li>
                                    </ul>
                                    <p style={{ fontWeight:"bold"}}>Expericence:</p>
                                    <ul>
                                        <li>Experiences in health care facility are preferred.</li>
                                        <li>All Shifts (Day, Night, Weekends).</li>
                                    </ul>
                                    <p style={{ fontWeight:"bold"}}>EFT: 1 Term Position (Sep 23, 2022 - Sep 23, 2023)</p>
                                    <p style={{ fontWeight:"bold"}}>Abilities and Skills:</p>
                                    <ul>
                                        <li>Ability to organize and prioritize assigned workload.</li>
                                        <li>Ability to respond to a variety of simultaneous demands.</li>
                                        <li>Ability to perform as a team member and seek assistance as necessary.</li>
                                        <li>Demonstrates initiative, flexibility, reliability and creativity.</li>
                                        <li>English fluency in both written and verbal communication.</li>
                                        <li>Verbal fluency in Indigenous languages (such as Ojibway) is an asset.</li>
                                    </ul>
                                    <p>Please download/fill in application form and send an email to:</p>
                                    <address>
                                    Glecy Estacio, RN.BN Assistant Director of Care/Resident Care Mangerr<br />
                                    Southeast Personal Care Home<br />
                                    <i className="fa fa-phone fa-lg"></i>  +1 (204) 269-7111, ext: 104<br />
                                    <i className="fa fa-fax fa-lg"></i> +1 (204) 269-8819<br />
                                    <i className="fa fa-envelope fa-lg"></i><a href="mailto:gestacio@sepch.ca"> gestacio@sepch.ca</a>
                                    </address>
                                    <h6>Job Application Form</h6>
                                    <a href="upload/files/SOUTHEAST_PERSONAL_CARE_HOME_ApplicationforEmployment.pdf" target="_blank" rel="noopener noreferrer">
                                        <Button>
                                            <i className="fa fa-download"></i> Download pdf
                                        </Button>
                                    </a>
                                    <p></p>
                                    <a href="upload/files/SOUTHEAST_PERSONAL_CARE_HOME_ApplicationforEmployment.docx" target="_blank" rel="noopener noreferrer">
                                        <Button>
                                            <i className="fa fa-download"></i> Download docx
                                        </Button>
                                    </a>
                                </CardBody>
                            </Card>
                        </UncontrolledCollapse>
                        <br></br>

                        <Button color="secondary" id="toggler2" style={{ marginBottom: '1rem', fontWeight:"bold", width: "250px", textAlign: "left"}}>
                            Dietary Aide <span class="badge badge-warning">Closed</span>
                        </Button>
                        <UncontrolledCollapse toggler="#toggler2">
                            <Card>
                                <CardBody>
                                <p style={{ fontWeight:"bold"}}>Education:</p>
                                    <ul>
                                        <li>Successful completion of the City of Winnipeg’s Food Handler Certificate Training Program. 
                                            This pre-requisite must be attained before the end of the 90-day probationary period.</li>
                                    </ul>
                                    <p style={{ fontWeight:"bold"}}>Expericence:</p>
                                    <ul>
                                        <li>Experiences in health care facility are preferred.</li>
                                        <li>All Shifts (Day, Night, Weekends).</li>
                                    </ul>
                                    <ul>
                                        <li>Minimum of one (1) year experience in a long term care setting preferred.</li>
                                        <li>Ability to operate related equipment.</li>
                                        <li>Demonstrates effective organizational skills with the ability to problem solve.</li>
                                        <li>Effective communication and interpersonal skills with staff, other disciplines, and external agencies to establish and maintain effective working relationships.</li>
                                        <li>Able to accept constructive criticism.</li>
                                        <li>Must be able to function effectively in a stressful environment with frequent interruptions.</li>
                                        <li>Maintain a clean, neat appearance, remaining compliant to dress code.</li>
                                        <li>Interested in the elderly, and in working to uphold the concepts of teamwork in caring for the needs of the residents’.</li>
                                        <li>Completes mandatory education.</li>
                                        <li>Must be mentally and physically healthy to meet the demands of the position.  
                                            Position involves physical activities such as standing, transferring and transporting food items and equipment.</li>
                                        <li>Must be able to arrive punctually and prepared to work.</li>
                                        <li>Demonstrated ability to maintain a satisfactory employment record, including attendance.</li>
                                        <li>A current Criminal Record Check and Adult Abuse Registry Check deemed satisfactory by Southeast Personal care Home.</li>
                                    </ul>
                                    <p>Please download/fill in application form and send an email to: </p>
                                    <address>
                                    Dave Tran, Manager of Dietary Services<br />
                                    Southeast Personal Care Home<br />
                                    <i className="fa fa-phone fa-lg"></i>  +1 (204) 269-7111, ext: 107<br />
                                    <i className="fa fa-fax fa-lg"></i> +1 (204) 269-8819<br />
                                    <i className="fa fa-envelope fa-lg"></i><a href="mailto:dtran@sepch.ca"> dtran@sepch.ca</a>
                                    </address>
                                    <h6>Job Application Form</h6>
                                    <a href="upload/files/SOUTHEAST_PERSONAL_CARE_HOME_ApplicationforEmployment.pdf" target="_blank" rel="noopener noreferrer">
                                        <Button>
                                            <i className="fa fa-download"></i> Download pdf
                                        </Button>
                                    </a>
                                    <p></p>
                                    <a href="upload/files/SOUTHEAST_PERSONAL_CARE_HOME_ApplicationforEmployment.docx" target="_blank" rel="noopener noreferrer">
                                        <Button>
                                            <i className="fa fa-download"></i> Download docx
                                        </Button>
                                    </a>
                                </CardBody>
                            </Card>
                        </UncontrolledCollapse>
                        <br>
                        </br>
                        <Button color="secondary" id="toggler3" style={{ marginBottom: '1rem', fontWeight:"bold", width: "250px", textAlign: "left" }}>
                            Housekeeping Aide <span class="badge badge-success">Open</span>
                        </Button>
                        <UncontrolledCollapse toggler="#toggler3">
                            <Card>
                                <CardBody>
                                    <p style={{ fontWeight:"bold"}}>Education:</p>
                                    <ul>
                                        <li>Minimum Grade 10, Manitoba standards, required.</li>
                                    </ul>
                                    <p style={{ fontWeight:"bold"}}>Expericence:</p>
                                    <ul>
                                        <li>Experiences in health care facility are preferred.</li>
                                    </ul>
                                    <p style={{ fontWeight:"bold"}}>EFT: 1 Year Term</p>
                                    <p style={{ fontWeight:"bold"}}>Closing date: 15 Oct, 2024</p>
                                    <p style={{ fontWeight:"bold"}}>Abilities and Skills:</p>
                                    <ul>
                                        <li>Ability to carry out duties in a professional manner, working under minimum or no supervision, and in compliance with confidentiality of facility and residents;</li>
                                        <li>Demonstrates effective organizational skills with the ability to problem solve;</li>
                                        <li>Functions effectively in a stressful environment with frequent interruptions;</li>
                                        <li>Maintains own physical, mental and emotional well-being to meet the job requirements (ie; prolonged standing, pushing, pulling, lifting and bending).</li>
                                        <li>Maintain a clean, neat appearance remaining compliant to dress code.</li>
                                        <li>Able to arrive punctually and prepared to work.</li>
                                        <li>Maintain a clean, neat appearance, remaining compliant to dress code.</li>
                                        <li>Interested in the elderly, and in working to uphold the concepts of teamwork in caring for the needs of the residents’.</li>
                                        <li>Must provide a current criminal record check at the time of hiring.</li>
                                    </ul>
                                    <p style={{ fontWeight:"bold"}}>Duties and Responsibilities:</p>
                                    <ul>
                                        <li>Ability to operate floor cleaning equipment</li>
                                        <li>Cleaning of assigned areas and tasks throughout facility (may include but not limited to: wet mopping, dusting, discarding garbage)</li>
                                        <li>Maintain the cleanliness of storage areas</li>
                                        <li>Ensures assigned cleaning duties are completed.</li>
                                        <li>Able to safely operate relevant housekeeping equipment.</li>
                                        <li>Reports equipment requiring repair or safety hazards to supervisor/maintenance department.</li>
                                        <li>Complies with all safety and health regulations (Sanitation Code, Workplace Safety & Health, Workplace Hazardous Material Information System (WHMIS), Principles of Routine Practices (Universal Precautions) and other legislated acts) when performing duties.</li>
                                        <li>May be required to perform other duties and functions related to this job description but not exceeding qualifications stated above.</li>
                                    </ul>
                                    <p>Please download/fill in application form and send an email to:</p>
                                    <address>
                                    Dave Tran, Manager of Support Services<br />
                                    Southeast Personal Care Home<br />
                                    <i className="fa fa-phone fa-lg"></i>  +1 (204) 269-7111, ext: 107<br />
                                    <i className="fa fa-fax fa-lg"></i> +1 (204) 269-8819<br />
                                    <i className="fa fa-envelope fa-lg"></i><a href="mailto:dtran@sepch.ca"> dtran@sepch.ca</a>
                                    </address>
                                    <h6>Job Application Form</h6>
                                    <a href="upload/files/SOUTHEAST_PERSONAL_CARE_HOME_ApplicationforEmployment.pdf" target="_blank" rel="noopener noreferrer">
                                        <Button>
                                            <i className="fa fa-download"></i> Download pdf
                                        </Button>
                                    </a>
                                    <p></p>
                                    <a href="upload/files/SOUTHEAST_PERSONAL_CARE_HOME_ApplicationforEmployment.docx" target="_blank" rel="noopener noreferrer">
                                        <Button>
                                            <i className="fa fa-download"></i> Download docx
                                        </Button>
                                    </a>
                                </CardBody>
                            </Card>
                        </UncontrolledCollapse>
                        <br></br>
                        <Button color="secondary" id="toggler4" style={{ marginBottom: '1rem', fontWeight:"bold", width: "250px", textAlign: "left" }}>
                            Nursing <span class="badge badge-warning">Closed</span>
                        </Button>
                        <UncontrolledCollapse toggler="#toggler4">
                            <Card>
                                <CardBody>


                                    <p style={{ fontWeight:"bold"}}>0.4 Evening RN – Term</p>
                                    <p style={{ fontWeight:"bold"}}>Closed</p>
                                    <p>Please download/fill in application form and send an email to:</p>
                                    <address>
                                    Glecy Estacio, RN.BN Assistant Director of Care/Resident Care Manger<br />
                                    Southeast Personal Care Home<br />
                                    <i className="fa fa-phone fa-lg"></i>  +1 (204) 269-7111, ext: 104<br />
                                    <i className="fa fa-fax fa-lg"></i> +1 (204) 269-8819<br />
                                    <i className="fa fa-envelope fa-lg"></i><a href="mailto:gestacio@sepch.ca"> gestacio@sepch.ca</a>
                                    </address>
                                    <h6>Job Application Form</h6>
                                    <a href="upload/files/SOUTHEAST_PERSONAL_CARE_HOME_ApplicationforEmployment.pdf" target="_blank" rel="noopener noreferrer">
                                        <Button>
                                            <i className="fa fa-download"></i> Download pdf
                                        </Button>
                                    </a>
                                    <p></p>
                                    <a href="upload/files/SOUTHEAST_PERSONAL_CARE_HOME_ApplicationforEmployment.docx" target="_blank" rel="noopener noreferrer">
                                        <Button>
                                            <i className="fa fa-download"></i> Download docx
                                        </Button>
                                    </a>
                                </CardBody>
                            </Card>
                        </UncontrolledCollapse>
                        <br></br>
                        <Button color="secondary" id="toggler5" style={{ marginBottom: '1rem', fontWeight:"bold", width: "250px", textAlign: "left" }}>
                            Recreation <span class="badge badge-warning">Closed</span>
                        </Button>
                        <UncontrolledCollapse toggler="#toggler5">
                            <Card>
                                <CardBody>

                                    <p style={{ fontWeight:"bold"}}>EFT:0.8</p>
                                    <ul>
                                        <li>Must Have Recreation Certificate</li>
                                        <li>Closed</li>
                                    </ul>
                                    <p>Please download/fill in application form and send an email to:</p>
                                    <address>
                                    Renee Gilbang, Social Worker<br />
                                    Southeast Personal Care Home<br />
                                    <i className="fa fa-phone fa-lg"></i>  +1 (204) 269-7111, ext: 116<br />
                                    <i className="fa fa-fax fa-lg"></i> +1 (204) 269-8819<br />
                                    <i className="fa fa-envelope fa-lg"></i><a href="mailto:rgilbang@sepch.ca"> rgilbang@sepch.ca</a>
                                    </address>
                                    <h6>Job Application Form</h6>
                                    <a href="upload/files/SOUTHEAST_PERSONAL_CARE_HOME_ApplicationforEmployment.pdf" target="_blank" rel="noopener noreferrer">
                                        <Button>
                                            <i className="fa fa-download"></i> Download pdf
                                        </Button>
                                    </a>
                                    <p></p>
                                    <a href="upload/files/SOUTHEAST_PERSONAL_CARE_HOME_ApplicationforEmployment.docx" target="_blank" rel="noopener noreferrer">
                                        <Button>
                                            <i className="fa fa-download"></i> Download docx
                                        </Button>
                                    </a>
                                </CardBody>
                            </Card>
                        </UncontrolledCollapse>
                        <br></br>
                        <Button color="secondary" id="toggler6" style={{ marginBottom: '1rem', fontWeight:"bold", width: "250px", textAlign: "left" }}>
                            Maintenance Worker <span class="badge badge-warning">Closed</span>
                        </Button>
                        <UncontrolledCollapse toggler="#toggler6">
                            <Card>
                                <CardBody>
                                    <p style={{ fontWeight:"bold"}}>EFT: 0.4 (Every Thursday and Friday)</p>
                                    <ul>
                                        <li>Closed</li>
                                    </ul>
                                    <p>Please download/fill in application form and send an email to:</p>
                                    <address>
                                    Dave Tran, Manager of Support Services<br />
                                    Southeast Personal Care Home<br />
                                    <i className="fa fa-phone fa-lg"></i>  +1 (204) 269-7111, ext: 107<br />
                                    <i className="fa fa-fax fa-lg"></i> +1 (204) 269-8819<br />
                                    <i className="fa fa-envelope fa-lg"></i><a href="mailto:dtran@sepch.ca"> dtran@sepch.ca</a>
                                    </address>
                                    <h6>Job Application Form</h6>
                                    <a href="upload/files/SOUTHEAST_PERSONAL_CARE_HOME_ApplicationforEmployment.pdf" target="_blank" rel="noopener noreferrer">
                                        <Button>
                                            <i className="fa fa-download"></i> Download pdf
                                        </Button>
                                    </a>
                                    <p></p>
                                    <a href="upload/files/SOUTHEAST_PERSONAL_CARE_HOME_ApplicationforEmployment.docx" target="_blank" rel="noopener noreferrer">
                                        <Button>
                                            <i className="fa fa-download"></i> Download docx
                                        </Button>
                                    </a>
                                </CardBody>
                            </Card>
                        </UncontrolledCollapse>

                    </div>             
                </div>
                <br></br>
            </div>
        );
    };
}

export default Job;